<template>
    <div>
        <div id="mse"></div>

        <van-cell title="" v-if="loading">
            <template #label>
                <van-skeleton title :row="5" style="padding: 0px" />
            </template>
        </van-cell>

        <template v-else>
            <t-content>
                <template #title>{{ video.title }}</template>
                <template #meta>
                    <template v-if="video.created_at">
                        {{ video.created_at }}
                        <span class="divider">/</span>
                    </template>

                    <template v-if="video.author">
                        {{ video.author }}
                        <span class="divider">/</span>
                    </template>

                    {{ video.category.name }}
                    <template v-if="video.url">
                        <span class="divider">/</span>
                        {{ video.times }}
                    </template>
                </template>
                <template #content>
                    <div class="player-list" v-if="video.accessories.length !== 0">
                        <ol>
                            <li :class="currentPlayUrl === ace.url ? 'aplayer-list-active' : null" v-for="(ace) in accessories" :key="ace.id" @click="handleChangeVideo(ace)">
                                <span :class="currentPlayUrl === ace.url ? 'aplayer-list-cur' : null"></span>
                                <span class="aplayer-list-title">{{ ace.title }}</span>
                                <span class="aplayer-list-times">{{ ace.author }}</span>
                            </li>
                        </ol>
                    </div>
                    <p v-html="video.content"></p>
                </template>
                <template #footer>
                    <van-row type="flex" justify="space-between">
                        <van-col span="12">阅读数 {{ video.views }}</van-col>
                        <van-col span="12"><collect v-if="!loading" :medium="video" /></van-col>
                    </van-row>
                </template>
            </t-content>
        </template>
    </div>
</template>

<script>
import { getMedia } from '@/api/media'
import Collect from '@/components/Collect'
import TContent from '@/components/TContent'
import wechatMixin from '@/utils/mixin'
import XGPlayer from 'xgplayer'
import HlsJsPlayer from 'xgplayer-hls.js'
import 'xgplayer-mp4'

export default {
    name: 'VideoShow',
    mixins: [wechatMixin],

    metaInfo () {
        return {
            title: this.video.title
        }
    },
    data() {
        return {
            player: null,
            loading: true,
            video: {
                id: null,
                title: '',
                author: '',
                times: 0,
                views: 0,
                url: '',
                accessories: []
            },
            playerOptions: {
                id: 'mse',
                url: '',
                fluid: true,
                poster: '',
                ignores: ['volume'],
                playsinline: true,
                playbackRate: [0.5, 0.75, 1, 1.5, 2],
                noLog: true,
                preload: 'meta',
                preloadTime: 5,
                closeVideoClick: false,
                closeVideoTouch: false,
                definitionActive: 'click',
                autoplay: true
            }
        }
    },
    methods: {
        handleChangeVideo (ace) {
            if (this.playerOptions.url === ace.url) {
                return
            }
            this.playerOptions.url = ace.url
            if (this.player.hasStart) {
                this.player.pause()
                setTimeout(() => {
                    this.player.src = ace.url
                }, 50)

            } else {
                this.player.start(ace.url)
            }
        }
    },
    computed: {
        currentPlayUrl () {
            return this.playerOptions.url
        },
        accessories () {
            return this.video.accessories
        }
    },
    components: { Collect, TContent },
    created() {
        const mediaId = this.$route.params.id
        getMedia(mediaId).then(res => {
            this.video = res.data
            this.playerOptions.poster = this.video.thumb
            if (this.video.url !== '') {
                this.playerOptions.url = this.video.url
            }
            if (this.video.accessories.length > 0) {
                this.playerOptions.url = this.video.accessories[0].url
            }
            if (this.playerOptions.url.indexOf('.m3u8') !== -1) {
                this.player = new HlsJsPlayer(this.playerOptions)
            } else {
                this.player = new XGPlayer(this.playerOptions)
            }

            this.loading = false
            this.registerWechatShare(this.video)
        })
    },
    beforeDestroy() {
        this.player && typeof this.player.destroy === 'function' && this.player.destroy();
    }
};
</script>

<style lang="less">
    .divider {
        margin: 0px 2px;
    }
    .player-list {
        margin: 10px 0px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.07), 0 1px 5px 0 rgba(0,0,0,.1);
        .aplayer-list-active {
            background: #efefef;
            transition: background-color .6s ease;
        }
        ol li {
            position: relative;
            height: 40px;
            line-height: 40px;
            padding: 0 15px;
            font-size: 14px;
            border-top: 1px solid #e9e9e9;
            cursor: pointer;
            transition: all .2s ease;
            overflow: hidden;
            margin: 0;
            text-align: start;
            display: flex;
            color: #323233;

            .aplayer-list-index {
                color: #666;
                margin-right: 12px;
            }
            .aplayer-list-cur {
                width: 3px;
                height: 40px;
                position: absolute;
                left: 0;
                transition: background-color .3s;
                background: #1989fa;
            }
            .aplayer-list-times {
                position: absolute;
                right: 15px;
            }
        }
    }
    .xgplayer-skin-default {
        .xgplayer-loading {
            width: 30px !important;
            height: 30px !important;
            margin: -15px auto auto -15px !important;
            svg {
                width: 30px;
                height: 30px;
            }
        }

        .xgplayer-enter .xgplayer-enter-spinner {
            width: 20px !important;
            height: 20px !important;
        }
    }
</style>
